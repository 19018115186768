
import { Component } from 'vue-property-decorator';
import { RoutesNames as GlobalRoutesNames } from '@/router/global/types';
import PwrVue from '@/components/PwrVue';
import maintenanceImg from '@/assets/maintenance.svg';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrBtn }
})
export default class SiteUnderMaintenanceView extends PwrVue {
  private imageClickCounter = 0;

  get img() {
    return maintenanceImg;
  }

  private goToOldOp(): void {
    window.location.href = 'http://ofertypracy.pwr.wroc.pl/default.aspx';
  }

  private onImageClick(): void {
    this.imageClickCounter += 1;

    if (this.imageClickCounter >= 4) {
      this.$router.push({ name: GlobalRoutesNames.LOGIN });
    }
  }
}
